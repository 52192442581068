.wrapper-background {
    background-color: #ffa9a9;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

.items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
}

.forgotPassword {
    display: flex;
    justify-content: right;
    
    margin: 0;
}

  .items > img {
    height: 100%;
    object-fit: contain;
  }

  .formSection {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
  }

  .connectedElements {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: space-between;
  }

  @media all and (max-width: 800px) {
    .items {
      -webkit-user-select: none;
      user-select: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%
    }
  }