.wrapper-background {
    background-color: #DFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
}

.button {
  -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;

    justify-content: space-between;
    gap: 15px;
}

  .h1 {
    text-align: center;
    font-size: 4vh;
  }

  .h2 {
    text-align: center;
    font-size: 2vh;
  }

  @media all and (max-width: 1200px) {
    .items {
      -webkit-user-select: none;
      user-select: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
    }

    .button {
      -webkit-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;

        justify-content: space-between;
        gap: 10px;
    }
  }

  @media all and (max-width: 800px) {
    .items {
      -webkit-user-select: none;
      user-select: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }

    .button {
      -webkit-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;

        justify-content: space-between;
        gap: 5px;
    }
  }