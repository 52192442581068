.wrapper-background {
    background-color: #feffd5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

.customizeOption {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;

  box-sizing: border-box;

  width: 8vw;
  aspect-ratio: 1;
}

.customizeOption > div {
  width: 100%;
  height: 100%;
}

.items {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;
  transform: translate(0, 15%);

  gap: 10px;
  justify-content: space-between;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text > * {
  margin: 0px;
}

.grid {
  width: 80%;
  height: 50vh;

  box-sizing: border-box;

  margin: 0;
}

.gridColors {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;

  box-sizing: border-box;

  aspect-ratio: 1;
}

.gridColors > div {
  width: 100%;
  height: 100%
}

.checkmark {
  position: absolute;
}

.render {
  position: absolute;
}

@media all and (max-width: 800px) {
  .items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;
    transform: translate(0, 4%);
  
    gap: 5px;
    justify-content: space-between;
  }

  .customizeOption {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: row;
  
    border-color: #000;
    border-width: 5px;
    border-style: solid;
  
    width: 15%;
    aspect-ratio: 1;
  }
  
  .customizeOption > div {
    width: 100%;
    height: 100%;
  }
}