.wrapper-background {
  background-color: #f2bdff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.items {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.items > img {
  height: 100%;
  object-fit: contain;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;
}

@media all and (max-width: 700px) {
  .items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
}