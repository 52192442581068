.wrapper-background {
    background-color: #e45a5a;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;

    text-align: center;
    font-size: 24px;
}

.headings {
    -webkit-text-fill-color: white;
}

@media all and (max-width: 800px) {
  .items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;

    text-align: center;
    font-size: 16px;
  }
}