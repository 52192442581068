.wrapper-background {
    background-color: #8b83ff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}