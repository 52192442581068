.wrapper-background {
  background-color: #D4A568;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.items {
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.items > img {
  height: 100%;
  object-fit: contain;
}

.hearts {
  display: flex;
  flex-direction: row;
  margin-top: 10%;
  column-gap: 20px;
  overflow: hidden;
  justify-content: space-around;
}

.hearts img {
  width: 100%;
  max-width: 35%;
  overflow: hidden;
}

@media all and (max-width: 750px) {
  .items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }

  .hearts {
    display: flex;
    flex-direction: row;
    margin-top: 10%;
    column-gap: 10px;
    overflow: hidden;
    justify-content: space-around;
  }
}