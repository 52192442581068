.wrapper-background {
    background-color: #C75843;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }  
  
  .items {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
  }

  .imageEnabled {
    filter: saturate(1);
    filter: brightness(1);
  }

  .imageDisabled {
    filter: saturate(0);
    filter: brightness(0.25);
  }

  @media all and (max-width: 1200px) {
    .items {
        -webkit-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
      }
  }

  @media all and (max-width: 750px) {
    .items {
        -webkit-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
      }
  }